<template>
  <div class="survey">
    <div class="top"></div>
    <div class="content">
      <div>
        <div class="left1">镇人代会
          <div class="icon-con" @click="toAccordingList()">
            更多 >
          </div>
        </div>
      </div>
      <div class="con_t" @click="toDetail(116)">
        <div class="con_t1">
          <img src="@/assets/images/survey/1.png" alt="" />
          十七届九次人代会
        </div>
        <div class="con_t2">
          <img src="@/assets/images/survey/2.png" alt="" />
        </div>
      </div>
      <div class="con_t" @click="toDetail(117)">
        <div class="con_t1">
          <img src="@/assets/images/survey/1.png" alt="" />
          十七届八次人代会
        </div>
        <div class="con_t2">
          <img src="@/assets/images/survey/2.png" alt="" />
        </div>
      </div>
      <div>
        <div class="left1">
          调研视察
          <div class="icon-con" @click="yearShow1 = true">
            {{ year1 ? year1 : "2021" }}
            <img src="@/assets/images/survey/icon_4.png" alt="" />
          </div>
        </div>
      </div>
      <div class="item-ist">
        <div
          class="item"
          @click="toDynamic(item.cid, item.name)"
          v-for="(item, index) in navBtn1"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div>
        <div class="left1">
          监督评议
          <div class="icon-con" @click="yearShow2 = true">
            {{ year2 ? year2 : "2021" }}
            <img src="@/assets/images/survey/icon_4.png" alt="" />
          </div>
        </div>
      </div>
      <div class="item-ist">
        <div
          class="item"
          @click="toDynamic(item.cid, item.name)"
          v-for="(item, index) in navBtn2"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div>
        <div class="left1">
          代表风采
          <div class="icon-con" @click="toDynamic(22,'代表风采')">
            更多 >
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in msglist"
        :key="index"
        class="list"
        @click="toArticleDetail(item.id)"
      >
        <div class="border">
          <div class="title">{{ item.title }}</div>
          <!-- 文字 -->
          <div v-if="item.tpe == 1 && item.brief.length > 0" class="list-text">
            {{ item.brief }}
          </div>
          <div class="foot">
            <span class="">{{ item.add_time }}</span>
            <span class="item-foot-4 ml-60 iconfont icon-check-line"></span>
            <span class="ml-10">{{ item.click_count }}</span>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="yearShow1" round position="bottom">
      <van-picker
        title="选择年份"
        show-toolbar
        :columns="years"
        @confirm="getYearOne"
        @cancel="yearShow1 = false"
      />
    </van-popup>
    <van-popup v-model="yearShow2" round position="bottom">
      <van-picker
        title="选择年份"
        show-toolbar
        :columns="years"
        @confirm="getYearTwo"
        @cancel="yearShow2 = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getInform, getDetail } from "../api";
export default {
  data() {
    return {
      navBtn1: [
        {
          cid: 28,
          name: "调研通知",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_1.png"),
        },
        {
          cid: 29,
          name: "工作报告",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_2.png"),
        },
        {
          cid: 30,
          name: "调研照片",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_7.png"),
        },
      ],
      navBtn2: [
        {
          cid: 31,
          name: "评议通知",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_5.png"),
        },
        {
          cid: 32,
          name: "部门报告",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_2.png"),
        },
        {
          cid: 37,
          name: "评议整改",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_6.png"),
        },
        {
          cid: 33,
          name: "调研照片",
          path: "dynamic",
          img: require("@/assets/images/survey/icon_7.png"),
        },
      ],
      msglist: [],
      yearShow1: false,
      yearShow2: false,
      years: ["2018", "2019", "2020", "2021"],
      year1: "",
      year2: "",
    };
  },
  created() {
    this.init();
    window.localStorage.setItem("year1", 2021);
    window.localStorage.setItem("year2", 2021);
  },
  methods: {
    init() {
      let params = {
        cid: 22,
        pagesize: 4,
      };
      getInform(params).then((res) => {
        console.log("res", res);
        this.msglist = res.data.items;
      });
    },
    getYearOne(value) {
      this.yearShow1 = false;
      this.year1 = value;
      window.localStorage.setItem("year1", value);
    },
    getYearTwo(value) {
      this.yearShow2 = false;
      this.year2 = value;
      window.localStorage.setItem("year2", value);
    },
    toAccordingList(id){
      this.$router.push('/accordingList');
    },
    toDetail(id) {
      this.$router.push({
        name: "accordingDetail",
        params: {
          id: id,
        },
      });
    },
    toDynamic(cid, name) {
      this.$router.push({
        name: 'dynamic',
        params: {
          name: name,
          cid: cid,
        },
      });
    },
    toArticleDetail(id) {
      this.$router.push({
        name: "articleDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.survey {
  width: 100%;
  position: relative;
  .top {
    height: 4.2rem;
    widows: 100%;
    background: url("../../../assets/images/survey/top_bg.png");
  }
  .content {
    width: 100%;
    padding: 0.3rem;
    color: #333333;
    font-size: 0.36rem;
    font-weight: 700;
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 2rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    .left1 {
      width: 100%;
      height: 24px;
      margin-right: 0.15rem;
      border-left: 5px solid #3b93ff;
      padding-left: 0.2rem;
      position: relative;
      .icon-con {
        width: 1.24rem;
        height: 0.48rem;
        border-radius: 0.12rem;
        background-color: #fafafa;
        text-align: center;
        line-height: 0.48rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.24rem;
        color: #666;
        font-weight: 100;
        img {
          width: 0.12rem;
          height: 0.2rem;
        }
      }
    }
    .item-ist {
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: space-around;
      font-size: 0.24rem;
      text-align: center;
      padding-top: 0.2rem;
      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-content: center;
        color: #666;
        font-weight: 100;
        img {
          width: 1rem;
          height: 1rem;
          margin: 0 auto 0.2rem;
        }
      }
    }
    .con_t {
      height: 40px;
      display: flex;
      font-weight: 100;
      justify-content: space-between;
      color: #3b93ff;
      background: #fafafa;
      border-radius: 5px;
      align-items: center;
      margin: 0.3rem 0;
      font-size: 0.32rem;
      .con_t1 {
        img {
          padding: 0 0.2rem;
        }
      }
      .con_t2 {
        img {
          padding: 0 0.2rem;
        }
      }
    }
    .list {
      width: 100%;
      background-color: #ffffff;
      border-radius: 0.1rem;
      border-bottom: 0.02rem solid rgba(153, 153, 153,.1);
      margin: 0.2rem 0;
      .border {
        padding: 0.24rem 0.26rem;
        font-weight: 100;
        color: #333;
        font-size: 0.28rem;
        .title {
          color: #333;
          letter-spacing: 0.02rem;
          margin-bottom: 0.15rem;
          max-height: 1rem;
          line-height: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .list-text {
          width: 100%;
          max-height: 1.5rem;
          line-height: 0.5rem;
          font-size: 0.24rem;
          color: #8b8b8b;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .foot {
          color: #8b8b8b;
          font-size: 0.26rem;
          margin-top: 0.3rem;
          display: flex;
          line-height: 120%;
          .item-foot-4 {
            width: 0.27rem;
            height: 0.15rem;
          }
          .ml-30 {
            margin-left: 0.3rem;
          }
          .ml-60 {
            margin-left: 0.6rem;
          }
          .ml-10 {
            margin-left: 0.1rem;
          }
        }
      }
    }
  }
}
</style>